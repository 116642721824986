import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgxFileDropModule } from 'ngx-file-drop';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { BytesPipe, NgBytesPipeModule } from 'angular-pipes';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SelectedAccountComponent } from './components/selected-account/selected-account.component';
import { AddAccountSummaryComponent } from './components/add-account-summary/add-account-summary.component';
import { FileAttachmentComponent } from './components/file-attachment/file-attachment.component';
import { OnlyNumbersDirective } from './helpers/only-numbers.directive';
import { SharedCostumerRegisterComponent } from './components/shared-costumer-register/shared-costumer-register.component';
import { DialogOKComponent } from './dialog-ok/dialog-ok.component';
import { DialogBadComponent } from './dialog-bad/dialog-bad.component';
import {MatTooltipModule} from '@angular/material/tooltip';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MensajeInternoComponent } from './components/mensaje-interno/mensaje-interno.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { RouterModule } from '@angular/router';
import { BankingEntityComponent } from './components/banking-entity/banking-entity.component';
import { PopoverComponent } from './components/popover/popover.component';
import { VisualizarTramiteComponent } from './components/visualizar-tramite/visualizar-tramite.component';
import { CaracterSfdcDirective } from './helpers/caracter-sfdc.directive';
import { InformacionClienteComponent } from './components/informacion-cliente/informacion-cliente.component';
import { ReplacePipe } from './pipelines/replace/replace.pipe';
import { CardSliderComponent } from './components/card-slider/card-slider.component';
import { ItemSliderAccountSummaryComponent } from './components/card-slider/customItem/item-slider-account-summary/item-slider-account-summary.component';
import { ItemSliderHistoricoPagosComponent } from './components/card-slider/customItem/item-slider-historico-pagos/item-slider-historico-pagos.component';
import { ItemSliderHistoricoConsumosComponent } from './components/card-slider/customItem/item-slider-historico-consumos/item-slider-historico-consumos.component';
import { ItemSliderHistoricoFacturasComponent } from './components/card-slider/customItem/item-slider-historico-facturas/item-slider-historico-facturas.component';
import { ItemSliderHistoricoTramitesComponent } from './components/card-slider/customItem/item-slider-historico-tramites/item-slider-historico-tramites.component';
import { ChoiceButtonComponent } from './forms/ui/choice-button/choice-button.component';
import { SwitchButtonComponent } from './forms/ui/switch-button/switch-button.component';
import { StatusMessageComponent } from './components/status-message/status-message.component';
import { DownloadCardComponent } from './components/download-card/download-card.component';
import { ConsentFormComponent } from './components/consent-form/consent-form.component';
import { FormalitiesUserResumeComponent } from './components/formalities-user-resume/formalities-user-resume.component';
import { FormalityComponent } from './components/formality/formality.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BodyAlertComponent } from './components/body-alert/body-alert.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CameraComponent } from './components/camera/camera.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { TakePhotoComponent } from './components/take-photo/take-photo.component';
import { WebcamModule } from 'ngx-webcam';

@NgModule({
  declarations: [
    SelectedAccountComponent,
    AddAccountSummaryComponent,
    OnlyNumbersDirective,
    FileAttachmentComponent,
    SharedCostumerRegisterComponent,
    DialogOKComponent,
    DialogBadComponent,
    MensajeInternoComponent,
    AlertDialogComponent,
    BankingEntityComponent,
    PopoverComponent,
    VisualizarTramiteComponent,
    CaracterSfdcDirective,
    InformacionClienteComponent,
    ReplacePipe,
    CardSliderComponent,
    ItemSliderAccountSummaryComponent,
    ItemSliderHistoricoPagosComponent,
    ItemSliderHistoricoConsumosComponent,
    ItemSliderHistoricoFacturasComponent,
    ItemSliderHistoricoTramitesComponent,
    ChoiceButtonComponent,
    SwitchButtonComponent,
    StatusMessageComponent,
    DownloadCardComponent,
    ConsentFormComponent,
    SharedCostumerRegisterComponent,
    FormalitiesUserResumeComponent,
    FormalityComponent,
    SpinnerComponent,
    BodyAlertComponent,
    PaginationComponent,
    CameraComponent,
    NotificationCenterComponent,
    TakePhotoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatButtonModule,
    MatIconModule,
    NgBytesPipeModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    RouterModule,
    WebcamModule,
  ],
  exports: [
    SelectedAccountComponent,
    AddAccountSummaryComponent,
    OnlyNumbersDirective,
    FileAttachmentComponent,
    SharedCostumerRegisterComponent,
    DialogOKComponent,
    DialogBadComponent,
    FormalitiesUserResumeComponent,
    MensajeInternoComponent,
    BankingEntityComponent,
    CaracterSfdcDirective,
    ReplacePipe,
    ItemSliderAccountSummaryComponent,
    ItemSliderHistoricoPagosComponent,
    ItemSliderHistoricoConsumosComponent,
    ItemSliderHistoricoFacturasComponent,
    ItemSliderHistoricoTramitesComponent,
    SharedCostumerRegisterComponent,
    ChoiceButtonComponent,
    StatusMessageComponent,
    DownloadCardComponent,
    ConsentFormComponent,
    FormalityComponent,
    SpinnerComponent,
    // modules
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxFileDropModule,
    MatButtonModule,
    MatIconModule,
    NgBytesPipeModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    RouterModule,
    PaginationComponent,
    NotificationCenterComponent,
    TakePhotoComponent,
  ],
  providers: [
    BytesPipe,
  ]
})
export class SharedModule {}
