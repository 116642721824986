import { Injectable } from '@angular/core';
// import { MatSnackBar } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  updatable = false;
  updatableSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private swUpdate: SwUpdate,
  ) {
    this.observForUpdates();
  }

  observForUpdates() {
    this.swUpdate.versionUpdates.subscribe({
      next: (version) => {
        console.info('changeset', version);
        this.updatableSubject.next(version.type !== 'NO_NEW_VERSION_DETECTED');
      }
    });

    const everySixHours$ = interval(600000);//10 min
    everySixHours$.subscribe(() => this.swUpdate.checkForUpdate());
  }

  updateVersion() {
    window.location.reload();
  }

  isUpdatable() {
    return this.updatableSubject.asObservable();
  }
}
