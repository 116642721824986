import { ParametrosEnvio } from '../models/parametrosEnvio'
import { SharedService } from 'src/app/shared/service/shared/shared.service'
import { TramitesService } from '../service/tramites/tramites.service'
import bodyValues from '../data/bodyValues.json'
import { SnackBarService } from '../service/snackbar/snackbar.service'
import { UpdateProfile } from '../models/personal-information/personal-information-request'
import { PersonalInformationService } from '../service/personal-information/personal-information.service'
import { mensajesTramites } from '../helpers/constantes'
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { AlertData } from '../interfaces/alert-dialog/alert-data'
import { Router } from '@angular/router'
import { ConfigLoaderService } from 'src/app/config/enviroment/config-loader.service'

export class FormalitiesGesture {
  parametrosEnvio: ParametrosEnvio = new ParametrosEnvio()
  updateProfile: UpdateProfile = new UpdateProfile()
  bodyValuesData = bodyValues

  constructor(
    private svcShared: SharedService,
    private svcTramites: TramitesService,
    private snackService: SnackBarService,
    private svcPersonalInfo: PersonalInformationService,
    private dialog: MatDialog,
    private router: Router,
    private configLoaderService: ConfigLoaderService
  ) { }

  buildInfoApiS1(parametros: ParametrosEnvio) {
    const content = `Nro. Cliente: ${parametros.nroCliente || '-' }
      <br/> Nombre Cliente: ${parametros.nombreCliente || '-' } ${parametros.apellidoCliente ?? ''}
      <br/> Tipo Relacion: ${parametros.tiporelacion || '-'} <br/> Relacion: ${parametros.relacion || '-' }
       <br/> Nombre del Solicitante: ${parametros.nombresolicitante || '-'
      } <br/> Tipo Documento: ${parametros.tipodocumento} <br/> Documento: ${parametros.documento || '-'
      } <br/> Tipo Telefono: ${parametros.tipotelefono || '-'
      } <br/> Nro. Telefono: ${parametros.telefono || '-'} <br/> Mail: ${parametros.email || this.svcShared.datosUsuario.email || '-'
      } <br/> Observaciones: ${parametros.bodyValues['[$observaciones]'] || '-'
      } <br/>`;
    let asunto = '';

    if (parametros.motivoCliente == 'Alta del suministro') {
      asunto = `Para el Cliente ${parametros.bodyValues['[$nombrenuevotitular]']} - Motivo Empresa ${parametros.bodyValues['[$motivo]']}.`;
    } else {
      const grupoCliente = this.svcShared.clienteSeleccionado != null
        ? this.svcShared.clienteSeleccionado.detalle.GrupoCliente
        : '';
      asunto = `Nro. Contacto mensajeContactoGenerado para el Cliente ${grupoCliente} Nro. ${parametros.nroCliente || '-'
        } Motivo Empresa ${parametros.bodyValues['[$motivo]'] || '-'}`;
    }
    const client = parametros.bodyValues['[$tipoPersona]'] === 'juridica'
      ? parametros.bodyValues['[$nombrerazonSocial]']
      : parametros.bodyValues['[$nombrenuevotitular]'];
    var asuntoAltaSuministro = `Para el Cliente ${client} - Motivo Empresa ${parametros.bodyValues['[$motivo]']}.`;

    asunto =
      parametros.motivoCliente == 'Alta del suministro'
        ? asuntoAltaSuministro
        : asunto;
    parametros.asuntoS1 = asunto;
    parametros.contentS1 = content;
    return parametros;
  }

  getUserBodyValues(parametros: ParametrosEnvio) {
    for (let key in this.svcShared.datosUsuario) {
      let propertyName = `[$${key}]`;
      if (
        key != 'opcion_sfdc' &&
        key != 'co_resp_email' &&
        key != 'id_contacto_sfdc' &&
        !parametros.bodyValues[propertyName]
      ) {
        const dato = this.svcShared.datosUsuario[key];
        parametros.bodyValues[propertyName] = String(dato);
      }
    }
    return parametros
  }

  getBodyValues(parametros: ParametrosEnvio): ParametrosEnvio {
    let clearObservaciones = false
    for (let key in this.bodyValuesData) {
      var propertyName = key
      if (parametros.bodyValues[propertyName] == undefined) {
        parametros.bodyValues[propertyName] = ''
      } else {
        if (
          (!String(parametros.bodyValues['[$observaciones]']).includes('##') ||
            clearObservaciones) &&
          key != '[$observaciones]'
        ) {
          if (!clearObservaciones) {
            parametros.bodyValues['[$observaciones]'] = ` ## ${this.bodyValuesData['[$observaciones]']
              }: ${parametros.bodyValues['[$observaciones]'] || '-'}`
            clearObservaciones = true
          }
          parametros.bodyValues['[$observaciones]'] += ` ## ${this.bodyValuesData[key]
            }: ${parametros.bodyValues[propertyName] || '-'}`
        }
      }
    }
    return this.getUserBodyValues(parametros)
  }

  actualizarDatosUsuario(parametros: ParametrosEnvio) {
    parametros.nroCliente =
      parametros.nroCliente == 0
        ? +this.svcShared.datosUsuario.nrocliente
        : parametros.nroCliente
    parametros.nombreCliente =
      parametros.nombreCliente == ''
        ? this.svcShared.datosUsuario.nombrecliente
        : parametros.nombreCliente
    parametros.tiporelacion =
      parametros.tiporelacion == ''
        ? this.svcShared.datosUsuario.tiporelacion
        : parametros.tiporelacion
    parametros.relacion =
      parametros.relacion == ''
        ? this.svcShared.datosUsuario.relacion
        : parametros.relacion
    parametros.nombresolicitante =
      parametros.nombresolicitante == ''
        ? this.svcShared.datosUsuario.nombresolicitante
        : parametros.nombresolicitante
    parametros.tipodocumento =
      parametros.tipodocumento == ''
        ? this.svcShared.datosUsuario.tipodoc
        : parametros.tipodocumento
    parametros.documento =
      parametros.documento == ''
        ? this.svcShared.datosUsuario.documento
        : parametros.documento
    parametros.tipotelefono =
      parametros.tipotelefono == ''
        ? this.svcShared.datosUsuario.tipotelefono
        : parametros.tipotelefono
    parametros.telefono =
      parametros.telefono == ''
        ? this.svcShared.datosUsuario.telefono
        : parametros.telefono
    parametros.email =
      parametros.email == ''
        ? this.svcShared.datosUsuario.email
        : parametros.email

    return parametros
  }

  async obtenerDatosEnvio(
    parametros: ParametrosEnvio
  ): Promise<ParametrosEnvio> {
    let result: ParametrosEnvio
    try {
      result = await this.actualizarDatosUsuario(parametros)
      result = await this.getBodyValues(result)
      result = await this.buildInfoApiS1(result)
    } catch (error) {
      result = null
    }
    return result
  }

  async guardarTramite(
    parametros: ParametrosEnvio,
    nuevoSuministro: boolean = false,
    updateFor: boolean = false,
    loginExterno: boolean = false,
    toS1only: boolean = false,
  ) {
    setTimeout(() => {
      this.svcShared.showOverlay.emit(true)
    }, 200);
    var resultado = ''
    let requestParameters = await this.obtenerDatosEnvio(parametros)

    if (requestParameters == null) {
      this.snackService.add(
        false,
        'Error',
        'Ocurrió un error al realizar la operacion, intentá nuevamente mas tarde'
      )
      return
    }

    return new Promise((resolve, reject) => {
      this.svcTramites
        .guardarTramite(
          requestParameters,
          this.svcShared.datosUsuario.opcion_sfdc,
          this.svcShared.datosUsuario.co_resp_email,
          '',
          this.svcShared.datosUsuario.id_contacto_sfdc,
          nuevoSuministro,
          updateFor,
          this.svcShared.encriptarDatos(requestParameters.nroCliente.toString()),
          loginExterno,
          toS1only
        )
        .subscribe({
          next: (data) => {
            this.svcShared.showOverlay.emit(false)
            if (data) {
              if (nuevoSuministro) {
                this.snackService.add(
                  true,
                  'Recibimos tu solicitud.',
                  'Muchas gracias por utilizar nuestra Oficina Virtual '
                    + (typeof data == 'string') ? data : '' + '.'
                )
              } else {
                try {
                  let resultado = JSON.parse(data)
                  // check 99 status code
                  let errorCode = resultado.Body?.CodigoResultado ?? null;
                  errorCode = errorCode || (resultado.codigo ?? null);
                  if (errorCode  && errorCode != '0' && errorCode != '1' && errorCode != '2') {
                    mensajesTramites.error = 'Ocurrió un error al realizar la operación, intente nuevamente mas tarde';
                    throw {error: 'Error con la validacion de datos'};
                  }

                  let state =  !resultado.codigo && resultado.codigo != -1 && resultado.codigo != 500
                  let titulo = state
                    ? mensajesTramites.tituloExito
                    : mensajesTramites.tituloError
                  let mensaje = state
                    ? mensajesTramites.exito
                    : mensajesTramites.error
                  mensaje =
                    resultado.codigo != 0 &&
                      resultado.codigo != 500 &&
                      resultado.codigo != -1
                      ? mensajesTramites.reiteracion
                      : mensaje

                  if (updateFor) {
                    mensaje = mensajesTramites.reiteracion
                  }

                  if (this.configLoaderService.environment.developmentMode) {
                    mensaje += ` ${resultado.mensaje}`
                  }

                  if (resultado.codigo != 2 && resultado.codigo != 1) {
                    let msg = ''
                    if (resultado.codigo != -1 && resultado.numeroContacto) {
                      msg = `
                        ${mensaje} 
                        <p>Nro. Caso: <b>${resultado.numeroContacto}</b></p>
                        <p>${mensajesTramites.exito2}</p>
                        <p>${mensajesTramites.exito3}</p>`
                    } else {
                      msg = mensaje
                    }
                    this.snackService.add(state, titulo, msg)
                  } else {
                    this.generarPopUpReiteracion(
                      parametros,
                      resultado.numeroContacto,
                      loginExterno
                    )
                  }
                } catch (_) {
                  this.snackService.add(
                    false,
                    mensajesTramites.tituloError,
                    mensajesTramites.error
                  )
                }
              }
              resolve(true)
            } else {
              this.snackService.add(
                false,
                'Error',
                'Ocurrió un error al realizar la operación, intente nuevamente mas tarde'
              )
              resolve(false)
            }
          },
          error: (error) => {
            this.svcShared.showOverlay.emit(false);

            let mensaje = 'Ocurrió un error al realizar la operación, intente nuevamente mas tarde';
            if (this.svcShared.datosUsuario.opcion_sfdc === 50 && error.error != null) {
              mensaje=error.error;
            }
            this.snackService.add(
              false,
              'Error',
              mensaje
            );
            reject(false);
          }
        });
    })
  }

  generarPopUpReiteracion(parametros: ParametrosEnvio, numeroCaso: string, loginExterno: boolean) {
    let htmlContent = `<h4 class="text-center mb-3">Reiteracion de caso</h4><p class="mb-0">Ya existe un caso creado con anterioridad por el motivo solicitado. N° contacto: ${numeroCaso}. ¿Deseas actualizar la información?</p>`
    let bodyAlert: AlertData
    bodyAlert = {
      htmlContent: htmlContent,
      state: true,
      showConfirmButtons: 1
    }
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      data: bodyAlert
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result.respuesta) {
        this.guardarTramite(parametros, false, true, loginExterno)
      } else {
        this.router.navigateByUrl('mi-cuenta')
      }
    })
  }
}
