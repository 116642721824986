import { Ihash } from '../interfaces/util/Ihash';
import { InformacionRecaudador } from 'src/app/shared/models/general/informacionRecaudador';
import { DeudaItem } from 'src/app/shared/models/general/DeudaItem';
import  config  from 'src/config.json';

import {
  faPrint,
  faDownload,
  faPaperPlane,
  faPlus,
  faCreditCard,
  faDollarSign,
  faChevronDown,
  faTasks,
  faWallet,
  faMoneyCheck,
  faBraille,
  faCheckCircle,
  faTimesCircle,
  faFileAlt,
  faInbox,
} from '@fortawesome/free-solid-svg-icons';

export const channel_web = 'ofv-web';

export const opcion_sfdc_efact = {
  id: 25,
  nombre: 'Adhesion Factura Electronica',
};
export const opcion_sfdc_deb_aut = {
  id: 26,
  nombre: 'Adhesion Debito automático',
};
export const opcion_sfdc_camb_tit = {
  id: 28,
  nombre: 'Cambio de titularidad',
};
export const opcion_sfdc_denuncia_ilic = {
  id: 31,
  nombre: 'Denuncia de Ilicitos',
};
export const opcion_sfdc_otros_recla = {
  id: 32,
  nombre: 'Consultas Trámites',
};
export const opcion_sfdc_brinda_lect = {
  id: 34,
  nombre: 'Brinda Lectura',
};
export const opcion_sfdc_rec_fact = {
  id: 35,
  nombre: 'No recepcion de Factura',
};
export const opcion_sfdc_obj_lect = {
  id: 37,
  nombre: 'Objecion de Lectura',
};
export const opcion_sfdc_poda_arboles = {
  id: 39,
  nombre: 'Poda árboles',
};
export const opcion_sfdc_rep_vereda = {
  id: 40,
  nombre: 'Reparación de vereda',
};
export const opcion_sfdc_tapa_med = {
  id: 42,
  nombre: 'Tapa de medidor',
};
export const opcion_sfdc_med_conexion = {
  id: 43,
  nombre: 'Temas relacionados al medidor y conexion',
};
export const opcion_sfdc_pago_no_acred = {
  id: 45,
  nombre: 'Pago no Acreditado',
};
export const opcion_sfdc_pago_CT_Simplificado = {
  id: 54,
  nombre: 'CT Digital - Simplificado',
};
export const opcion_sfdc_pago_CT_DatosPersonales = {
  id: 55,
  nombre: 'CT Digital - Datos Personales',
}
export const opcion_sfdc_electrodependiente = {
  id: 56,
  nombre: 'Consulta por electrodependiente',
};
export const opcion_sfdc_libreDeuda = {
  id: 57,
  nombre: 'Solicitud de Certificado de Libre Deuda',
};

export const extensiones_archivos_validas = ['JPG', 'PNG', 'PDF', 'JPEG'];
export const MAXSIZEATTACH = 5242880; //5MB

export const mensajesTramites = {
  error:
    'Lo sentimos, no logramos procesar tu solicitud. Por favor, volvé a intentarlo en unos minutos. Muchas gracias.',
  exito:
    'Tu trámite fue generado exitosamente.<br>',
  exito2: 'Vas a recibir la respuesta por correo electrónico en un máximo de 10 días hábiles.',
  exito3: '¡Gracias por utilizar la Oficina Virtual!',
  tituloError: 'Error de operación',
  tituloExito: 'Operación exitosa',
  reiteracion:
    'Existe un caso creado con anterioridad por el motivo solicitado. Se ha generado una reiteración del mismo.',
};

export const bancosDisponibles: Ihash[] = [
  { id: '00007', name: 'BANCO DE GALICIA Y BUENOS AIRES S.A.U.' },
  { id: '00011', name: 'BANCO DE LA NACION ARGENTINA' },
  { id: '00014', name: 'BANCO DE LA PROVINCIA DE BUENOS AIRES' },
  { id: '00015', name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA' },
  { id: '00016', name: 'CITIBANK N.A.' },
  { id: '00017', name: 'BANCO BBVA ARGENTINA S.A.' },
  { id: '00020', name: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.' },
  { id: '00027', name: 'BANCO SUPERVIELLE S.A.' },
  { id: '00029', name: 'BANCO DE LA CIUDAD DE BUENOS AIRES' },
  { id: '00034', name: 'BANCO PATAGONIA S.A.' },
  { id: '00044', name: 'BANCO HIPOTECARIO S.A.' },
  { id: '00045', name: 'BANCO DE SAN JUAN S.A.' },
  { id: '00065', name: 'BANCO MUNICIPAL DE ROSARIO' },
  { id: '00072', name: 'BANCO SANTANDER RIO S.A.' },
  { id: '00083', name: 'BANCO DEL CHUBUT S.A.' },
  { id: '00086', name: 'BANCO DE SANTA CRUZ S.A.' },
  { id: '00093', name: 'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M' },
  { id: '00094', name: 'BANCO DE CORRIENTES S.A.' },
  { id: '00097', name: 'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ' },
  { id: '00143', name: 'BRUBANK S.A.U.' },
  { id: '00147', name: 'BANCO INTERFINANZAS S.A.' },
  { id: '00150', name: 'HSBC BANK ARGENTINA S.A.' },
  { id: '00158', name: 'OPEN BANK ARGENTINA S.A.' },
  { id: '00165', name: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO' },
  { id: '00191', name: 'BANCO CREDICOOP COOPERATIVO LIMITADO' },
  { id: '00198', name: 'BANCO DE VALORES S.A.' },
  { id: '00247', name: 'BANCO ROELA S.A.' },
  { id: '00254', name: 'BANCO MARIVA S.A.' },
  { id: '00259', name: 'BANCO ITAU ARGENTINA S.A.' },
  { id: '00262', name: 'BANK OF AMERICA, NATIONAL ASSOCIATION' },
  { id: '00266', name: 'BNP PARIBAS' },
  { id: '00268', name: 'BANCO PROVINCIA DE TIERRA DEL FUEGO' },
  { id: '00269', name: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGU' },
  { id: '00277', name: 'BANCO SAENZ S.A.' },
  { id: '00281', name: 'BANCO MERIDIAN S.A.' },
  { id: '00285', name: 'BANCO MACRO S.A.' },
  { id: '00299', name: 'BANCO COMAFI SOCIEDAD ANONIMA' },
  { id: '00300', name: 'BANCO DE INVERSION Y COMERCIO EXTERIOR S' },
  { id: '00301', name: 'BANCO PIANO S.A.' },
  { id: '00305', name: 'BANCO JULIO SOCIEDAD ANONIMA' },
  { id: '00309', name: 'BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL' },
  { id: '00310', name: 'BANCO DEL SOL S.A.' },
  { id: '00311', name: 'NUEVO BANCO DEL CHACO S. A.' },
  { id: '00312', name: 'BANCO VOII S.A.' },
  { id: '00315', name: 'BANCO DE FORMOSA S.A.' },
  { id: '00319', name: 'BANCO CMF S.A.' },
  { id: '00321', name: 'BANCO DE SANTIAGO DEL ESTERO S.A.' },
  { id: '00322', name: 'BANCO INDUSTRIAL S.A.' },
  { id: '00330', name: 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA' },
  { id: '00331', name: 'BANCO CETELEM ARGENTINA S.A.' },
  { id: '00332', name: 'BANCO DE SERVICIOS FINANCIEROS S.A.' },
  { id: '00336', name: 'BANCO BRADESCO ARGENTINA S.A.U.' },
  { id: '00338', name: 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.' },
  { id: '00339', name: 'RCI BANQUE S.A.' },
  { id: '00340', name: 'BACS BANCO DE CREDITO Y SECURITIZACION S' },
  { id: '00341', name: 'BANCO MASVENTAS S.A.' },
  { id: '00384', name: 'WILOBANK S.A.' },
  { id: '00386', name: 'NUEVO BANCO DE ENTRE RÍOS S.A.' },
  { id: '00389', name: 'BANCO COLUMBIA S.A.' },
  { id: '00426', name: 'BANCO BICA S.A.' },
  { id: '00431', name: 'BANCO COINAG S.A.' },
  { id: '00432', name: 'BANCO DE COMERCIO S.A.' },
  { id: '00435', name: 'BANCO SUCREDITO REGIONAL S.A.U.' },
  { id: '00448', name: 'BANCO DINO S.A.' },
  { id: '00515', name: 'BANK OF CHINA LIMITED SUCURSAL BUENOS AI' },
  { id: '44059', name: 'FORD CREDIT COMPAÑIA FINANCIERA S.A.' },
  { id: '44077', name: 'COMPAÑIA FINANCIERA ARGENTINA S.A.' },
  { id: '44088', name: 'VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F' },
  { id: '44090', name: 'CORDIAL COMPAÑÍA FINANCIERA S.A.' },
  { id: '44092', name: 'FCA COMPAÑIA FINANCIERA S.A.' },
  { id: '44093', name: 'GPAT COMPAÑIA FINANCIERA S.A.U.' },
  { id: '44094', name: 'MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT' },
  { id: '44095', name: 'ROMBO COMPAÑÍA FINANCIERA S.A.' },
  { id: '44096', name: 'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.' },
  { id: '44098', name: 'PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER' },
  { id: '44099', name: 'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA' },
  { id: '45030', name: 'NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.' },
  { id: '45056', name: 'MONTEMAR COMPAÑIA FINANCIERA S.A.' },
  { id: '45072', name: 'TRANSATLANTICA COMPAÑIA FINANCIERA S.A.' },
  { id: '65203', name: 'CREDITO REGIONAL COMPAÑIA FINANCIERA S.A' },
];

export const bancosTramiteSimplificado: Ihash[] = [
  {id:'0005',name:'ABN AMRO BANK N.V. D.A.'},
  {id:'0295',name:'AMERICAN EXPRESS BANK'},
  {id:'0266',name:'B.N. PARIS DEBITO AUTOMATICO'},
  {id:'0265',name:'BANCA LAVORO'},
  {id:'0426',name:'BANCO BICA'},
  {id:'0331',name:'BANCO CETELEM'},
  {id:'0029',name:'BANCO CIUDAD'},
  {id:'0431',name:'BANCO COINAG'},
  {id:'0389',name:'BANCO COLUMBIA'},
  {id:'0432',name:'BANCO DE COMERCIO S.A.'},
  {id:'0448',name:'BANCO DINO'},
  {id:'0017',name:'BANCO FRANCES'},
  {id:'0322',name:'BANCO INDUSTRIAL S.A.'},
  {id:'0259',name:'BANCO ITAU ARGENTINA S'},
  {id:'0305',name:'BANCO JULIO SA'},
  {id:'0341',name:'BANCO MASVENTAS'},
  {id:'0281',name:'BANCO MERIDIAN S.A.'},
  {id:'0034',name:'BANCO PATAGONIA SA'},
  {id:'0301',name:'BANCO PIANO'},
  {id:'0277',name:'BANCO SAENZ'},
  {id:'0072',name:'BANCO SANTANDER RIO S.A.'},
  {id:'0312',name:'BANCO VOII'},
  {id:'0336',name:'BCO BRADESCO ARGENTINA'},
  {id:'0338',name:'BCO DE SERV Y TRANSAC'},
  {id:'0046',name:'BCO DO BRASIL'},
  {id:'0303',name:'BCO FINANSUR'},
  {id:'0147',name:'BCO INTERFINANZAS S.A.'},
  {id:'0306',name:'BCO PRIVADO DE INVERSIONES'},
  {id:'0300',name:'BICE'},
  {id:'0143',name:'BRUBANK'},
  {id:'0408',name:'CIA FINANC ARGEN'},
  {id:'0016',name:'CITIBANK'},
  {id:'0319',name:'CO METROPOLITANA'},
  {id:'0299',name:'COMAFI'},
  {id:'0191',name:'CREDICOP'},
  {id:'0439',name:'CREDILOGROS CIA FINANCIERA'},
  {id:'0434',name:'CREDITO REGIONAL'},
  {id:'0094',name:'DE CORRIENTES'},
  {id:'0059',name:'DE ENTRE RIOS'},
  {id:'0315',name:'DE FORMOSA'},
  {id:'0093',name:'DE LA PAMPA'},
  {id:'0309',name:'DE LA RIOJA'},
  {id:'0097',name:'DE NEUQUEN'},
  {id:'0317',name:'DE RIO NEGRO'},
  {id:'0045',name:'DE SAN JUAN'},
  {id:'0086',name:'DE SANTA CRUZ'},
  {id:'0330',name:'DE SANTA FE'},
  {id:'0198',name:'DE VALORES'},
  {id:'0083',name:'DEL CHUBUT'},
  {id:'0310',name:'DEL SOL'},
  {id:'0060',name:'DEL TUCUMAN'},
  {id:'0297',name:'EXPRINTER'},
  {id:'0440',name:'FIAT CREDIT CIA. FIN.'},
  {id:'0405',name:'FORD CREDIT'},
  {id:'0007',name:'GALICIA'},
  {id:'0441',name:'GMAC'},
  {id:'0044',name:'HIPOTECARIO'},
  {id:'0150',name:'HSBC BANK ARG.'},
  {id:'0015',name:'IND.COM.BANK OF CHINA'},
  {id:'0428',name:'LA CAPITAL DEL PLATA'},
  {id:'0010',name:'LLOYDS TSB BANK'},
  {id:'0285',name:'MACRO'},
  {id:'0414',name:'MAS VENTAS FINAN'},
  {id:'0442',name:'MERCEDES BENZ CIA FIN'},
  {id:'0406',name:'METROPOLIS CIA FIN SA'},
  {id:'0413',name:'MONTEMAR CIA FIN'},
  {id:'0065',name:'MUNIC.DE ROSARIO'},
  {id:'0011',name:'NACION'},
  {id:'0386',name:'NUEVO BANCO ENTRE RIOS'},
  {id:'0387',name:'NUEVO BANCO SUQUIA D.A.'},
  {id:'0388',name:'NUEVO BCO BISEL'},
  {id:'0311',name:'NVO DEL CHACO'},
  {id:'0269',name:'ORIENTAL URUGUAY'},
  {id:'0020',name:'PROV CORDOBA'},
  {id:'0014',name:'PROVINCIA BS AS'},
  {id:'0079',name:'REGIONAL DE CUYO'},
  {id:'0247',name:'ROELA'},
  {id:'0321',name:'STGO DEL ESTERO'},
  {id:'0006',name:'SUDAMERIS'},
  {id:'0027',name:'SUPERVIELLE'},
  {id:'0268',name:'TIERRA DEL FUEGO'},
  {id:'0415',name:'TRANSATLA CIA FINAN SA'},
  {id:'0384',name:'WILOBANK S.A.'},
];

export const bancosTramiteSimplificadoBKP: Ihash[] = [
  {id:'0029',name:'BANCA LAVORO'},
  {id:'0030',name:'BANCO BICA'},
  {id:'0031',name:'BANCO CETELEM'},
  {id:'0032',name:'BANCO CIUDAD'},
  {id:'0033',name:'BANCO COINAG'},
  {id:'0034',name:'BANCO COLUMBIA'},
  {id:'0035',name:'BANCO DE COMERCIO S.A.'},
  {id:'0036',name:'BANCO DINO'},
  {id:'0037',name:'BANCO FRANCES'},
  {id:'0038',name:'BANCO INDUSTRIAL S.A.'},
  {id:'0039',name:'BANCO ITAU ARGENTINA S'},
  {id:'0040',name:'BANCO JULIO SA'},
  {id:'0041',name:'BANCO MASVENTAS'},
  {id:'0042',name:'BANCO MERIDIAN S.A.'},
  {id:'0043',name:'BANCO PATAGONIA SA'},
  {id:'0044',name:'BANCO PIANO'},
  {id:'0045',name:'BANCO SAENZ'},
  {id:'0046',name:'BANCO SANTANDER RIO S.A.'},
  {id:'0047',name:'BANCO VOII'},
  {id:'0048',name:'BCO DE SERV Y TRANSAC'},
  {id:'0049',name:'BCO INTERFINANZAS S.A.'},
  {id:'0050',name:'BICE'},
  {id:'0051',name:'BRUBANK'},
  {id:'0052',name:'CIA FINANC ARGEN'},
  {id:'0053',name:'CITIBANK'},
  {id:'0054',name:'CO METROPOLITANA'},
  {id:'0055',name:'COMAFI'},
  {id:'0056',name:'CREDICOP'},
  {id:'0057',name:'CREDITO REGIONAL'},
  {id:'0058',name:'DE CORRIENTES'},
  {id:'0059',name:'DE ENTRE RIOS'},
  {id:'0060',name:'DE FORMOSA'},
  {id:'0061',name:'DE LA PAMPA'},
  {id:'0062',name:'DE LA RIOJA'},
  {id:'0063',name:'DE NEUQUEN'},
  {id:'0064',name:'DE RIO NEGRO'},
  {id:'0065',name:'DE SAN JUAN'},
  {id:'0066',name:'DE SANTA CRUZ'},
  {id:'0067',name:'DE SANTA FE'},
  {id:'0068',name:'DE VALORES'},
  {id:'0069',name:'DEL CHUBUT'},
  {id:'0070',name:'DEL SOL'},
  {id:'0071',name:'DEL TUCUMAN'},
  {id:'0072',name:'EXPRINTER'},
  {id:'0073',name:'FIAT CREDIT CIA. FIN.'},
  {id:'0074',name:'FORD CREDIT'},
  {id:'0075',name:'GALICIA'},
  {id:'0076',name:'HIPOTECARIO'},
  {id:'0077',name:'HSBC BANK ARG.'},
  {id:'0078',name:'IND.COM.BANK OF CHINA'},
  {id:'0079',name:'LLOYDS TSB BANK'},
  {id:'0080',name:'MACRO'},
  {id:'0081',name:'MAS VENTAS FINAN'},
  {id:'0082',name:'MERCEDES BENZ CIA FIN'},
  {id:'0083',name:'METROPOLIS CIA FIN SA'},
  {id:'0084',name:'MONTEMAR CIA FIN'},
  {id:'0085',name:'MUNIC.DE ROSARIO'},
  {id:'0086',name:'NACION'},
  {id:'0087',name:'NUEVO BANCO ENTRE RIOS'},
  {id:'0088',name:'NVO DEL CHACO'},
  {id:'0089',name:'ORIENTAL URUGUAY'},
  {id:'0090',name:'PROV CORDOBA'},
  {id:'0091',name:'PROVINCIA BS AS'},
  {id:'0092',name:'REGIONAL DE CUYO'},
  {id:'0093',name:'ROELA'},
  {id:'0094',name:'STGO DEL ESTERO'},
  {id:'0095',name:'SUDAMERIS'},
  {id:'0096',name:'SUPERVIELLE'},
  {id:'0097',name:'TIERRA DEL FUEGO'},
  {id:'0098',name:'TRANSATLA CIA FINAN SA'},
  {id:'0099',name:'WILOBANK S.A.'},
];

export const listaReclamos: string[] = [
  'pago no acreditado digital',
  'denuncia personal anticorrupción digital',
  'no recepción o retraso de la factura digital',
  'consulta comercial digital',
];

export const rutasHabilitaSeleccionSuministro: string[] = [
  '/informacion-personal',
  '/pagar',
  '/all-accounts'
];

//FLUJO PAGOS

export const listaRecaudadores: InformacionRecaudador[] = config.pagos.tarjetas;

export const listaConfDeudas: DeudaItem[] = [
  {
    codigo: 'UF',
    mensaje: 'Saldo Última Factura',
    mensajeLargo: 'Pagar total con tarjeta',
    icono: faCreditCard,
    deuda: null
  },
  {
    codigo: 'DV',
    mensaje: 'Saldo total',
    mensajeLargo: 'Pagar total con tarjeta',
    icono: faCreditCard,
    deuda: null
  },
  {
    codigo: 'DA',
    mensaje: 'Saldo anterior',
    mensajeLargo: 'Pagar saldo anterior con tarjeta',
    icono: faWallet,
    deuda: null
  },
  {
    codigo: 'DE',
    mensaje: 'Saldo parcial ENRE',
    mensajeLargo: 'Pagar saldo parcial por reclamo',
    icono: faMoneyCheck,
    deuda: null
  },
  {
    codigo: 'DR',
    mensaje: 'Saldo total ENRE',
    mensajeLargo: 'Pagar reclamo ENRE',
    icono: faCreditCard,
    deuda: null
  },
  {
    codigo: 'BAR',
    mensaje: 'Saldo Convenio',
    mensajeLargo: 'Pagar cuota convenio',
    icono: faWallet,
    deuda: null
  },
  {
    codigo: 'CNR',
    mensaje: 'Consumo no registrado',
    mensajeLargo: 'Consumo no registrado',
    icono: faCreditCard,
    deuda: null
  },
];
