export class GetProfile {
  username: string = '';
  origen: string = '';
  tipoCliente: string = '';
}

export class UpdateProfile {
  username: string = '';
  nombre: string = '';
  apellidoPaterno: string = '';
  customerId: number = 0;
  segundoNombre: string = '';
  tipoCliente: string = '';
  origen: string = '';
  tipoTelefonoPrincipal: string = '';
  telefonoPrincipal: string = '';
  tipoTelefonoSecundario: string = '';
  telefonoSecundario: string = '';
}
