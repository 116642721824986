import {IHash} from '../interfaces/IHash';

export class ParametrosEnvio {
  nroCliente: number= 0;
  nroContacto: number= 0;
  motivoCliente: string = '';
  nombreCliente: string = '';
  apellidoCliente: string = '';
  tiporelacion: string = '';
  relacion: string = '';
  nombresolicitante: string = '';
  tipodocumento: string = '';
  documento: string = '';
  tipotelefono: string = '';
  telefono: string = '';
  estado: string = '';
  resolucion: string = '';
  fechaInicio: string = '';
  fechaFin: string = '';
  fechaCierre: string = '';
  carta: string = '';
  observaciones: string = '';
  email: string = '';
  motivoTarifaSocial: string = '';
  //ExtraData: string = ''; object??
  bodyValues: any = {};
  envio: string = '';
  email4: string = '';
  email5: string = '';
  email6: string = '';
  asuntoOriginalValues: string[];
  asuntoRespuestaValues: string[];
  asutoOriginalTemplate: string = 'Nro. Contacto {1} para el Cliente Nro. {2} - Motivo Empresa {3}.';
  asutoRespuestaTemplate: string = 'Nro. Contacto {1} para el Cliente Nro. {2} - Motivo Empresa {3}.';
  templateRespuesta: string = '';
  templateOriginal: string = '';
  attachmentsPath: string[];
  attachments: Documento[] = [];
  totalcompra: string = '';
  subtotalxarticulo: string = '';
  contentS1:string = '';
  asuntoS1:string = '';
}

export class Documento{
  nombre:string = '';
  link:string = '';
  extension:string = '';
  bytes:string = '';
  tipo?:string = '';
}

export class TramiteRequest{
  cuentaContrato: string;
  parametros: ParametrosEnvio = new ParametrosEnvio();
  co_resp_email: string = '';
  id_contacto_sfdc: string = '';
  opcion_sfdc: number = 0;
}
