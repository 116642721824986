import { Injectable } from '@angular/core';

export abstract class TagManagerService {

  constructor() { }

  public abstract emitPageEvent(url: string): void;

  public abstract emitEvent(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue: number
  ): void;

  public abstract emitRouterEvent(
    screen_view: string,
    app_name: string,
    screen_name: string,
    app_version: string
  ): void;
}
