import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { TagManagerService } from '../domain/tag-manager.service';

declare const _satellite: any;
@Injectable({
  providedIn: 'root'
})
export class AdobeDtmService implements TagManagerService {
  constructor() {
    this.loadScript().then(() => {}).catch(() => {});
  }

  public emitPageEvent(page: string): void {
    const url = window.location.href;
    if (typeof _satellite !== 'undefined') {
      this.dtmPageView(page, url);
    } else {
      setTimeout(() => {
        this.dtmPageView(page, url);
      }, 2000);
    }
  }

  private dtmPageView(pagename: string, url: string) {
    pagename = pagename.replace('/', '');
    const data = {pagename, url};
    try {
      _satellite.track("virtualpagenavigation", data);
    } catch(e) {
      console.error('Error call adobe script', e);
    }
  }


  public emitEvent(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue: number): void {
    // throw new Error('Adobe DTM emitEvent not implemented.');
  }

  public emitRouterEvent(screen_view: string, app_name: string, screen_name: string, app_version: string): void {
    // throw new Error('Adobe DTM emitRouterEvent not implemented.');
  }

  private loadScript() {
    const scriptUrl = environment.dtmScript;
    return new Promise(resolve => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

}
