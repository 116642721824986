import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(value: string, intercambio: string, intercambioDos: string): string {

    if (!value || !intercambio || !intercambioDos) {
      return value;
    }
    let helper = '@';
    var valueHolder = '';
    valueHolder = value.replace(new RegExp(this.escapeStr(intercambio), 'g'), helper);

    value = valueHolder.replace(new RegExp(this.escapeStr(intercambioDos), 'g'), intercambio);
    return value.replace(new RegExp(this.escapeStr(helper), 'g'), intercambioDos);
  }

  escapeStr(str) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}
