import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { AuthGuard } from  'src/app/modules/auth/guard/auth-guard.guard'
import {UrlSerializerRemoveQuestionMark} from 'src/app/shared/helpers/urlSerializerRemoveQuestionMark'

const routes: Routes = [

  { path: '', loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: 'mi-cuenta', loadChildren: () => import('./modules/account-summary/account-summary.module').then(m => m.AccountSummaryModule)
  , canActivate: [AuthGuard] },
  { path: 'pagos-y-facturas', loadChildren: () => import('./modules/payments-and-invoices/payments-and-invoices.module').then(m => m.PaymentsAndInvoicesModule), canActivate: [AuthGuard] },
  { path: 'tramites', loadChildren: () => import('./modules/formalities/formalities.module').then(m => m.FormalitiesModule), canActivate: [AuthGuard] },
  { path: 'informacion-personal', loadChildren: () => import('./modules/personal-information/personal-information.module').then(m => m.PersonalInformationModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64] // [x, y]
    })
  ],
  exports: [RouterModule],
  providers:[
    { provide: UrlSerializer, useClass: UrlSerializerRemoveQuestionMark },
  ]
})
export class AppRoutingModule { }
