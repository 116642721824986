export class UserInfo{

  nrocliente:string = '';
  nombrecliente:string = '';
  tiporelacion:string = '';
  relacion:string = '';
  nombresolicitante:string = '';
  tipodoc:string = '';
  documento:string = '';
  tipotelefono:string = '';
  telefono:string = '';
  email:string = '';
  confirmEmail:string ='';
  opcion_sfdc : number = 0;
  co_resp_email: string = '';
  id_contacto_sfdc: string = '';
  username:string = '';
  origen:string = '';
  tipoCliente:string = '';
  nombre:string = '';
  apellidoPaterno:string = '';
  customerId:number = 0;
  segundoNombre:string = '';
  telefonoAlt:string = '';
  password:string = '';
  passwordNueva:string = '';
  passwordVieja:string = '';
  codigoEmpresa:string = '';
  direccion: string = '';
  suscritoEFactura: boolean = false;
  cambioTitularidad?: number = 0;
}
