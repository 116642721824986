import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ParametrosEnvio, TramiteRequest } from '../../models/parametrosEnvio';
import { Observable } from 'rxjs';
import { ConfigLoaderService } from 'src/app/config/enviroment/config-loader.service';
import { CorteSuministroBody } from 'src/app/modules/formalities/models/corteSuministroBody';
import { CorteSuministroResponse } from 'src/app/modules/formalities/models/corteSuministroResponse';

@Injectable({
  providedIn: 'root'
})
export class TramitesService {
  constructor(
    private client: HttpClient,
    private configLoaderService: ConfigLoaderService
  ) { }
  //Mock observable
  casoCorteEmergencies(data: CorteSuministroBody): Observable<CorteSuministroResponse> {
     return this.client
       .post<CorteSuministroResponse>(`${this.configLoaderService.environment.endpoint}/api/Tramite/Tramite/CasoEmergencies`,data);

  }

  guardarTramite(
    parametros: ParametrosEnvio,
    opcion_sfdc: number,
    co_resp_email: string,
    ListaDocumentos: string,
    id_contacto_sfdc: string,
    nuevoSuministro: boolean = false,
    updateFor: boolean = false,
    encriptCtaCto: string = '',
    loginExterno: boolean = false,
    toS1only: boolean = false
  ): Observable<any> {
    const params = new HttpParams().append(
      'opcion_sfdc',
      opcion_sfdc.toString()
    )
    if(nuevoSuministro) {
      return this.client.post(
        `${this.configLoaderService.environment.endpoint}/api/Tramite/Tramite/NuevoSuministro`,
        parametros, { params,responseType: 'text' }
      );
    } else if (toS1only) {
      return this.client.post(
        `${this.configLoaderService.environment.endpoint}/api/Tramite/Tramite/otrosTramitesS1`,
        parametros, { params,responseType: 'text' }
      );
    } else {
      const headerDictSupply = {
        RequestKey: 'cuentaContrato',
        TipoValidacion: loginExterno? 'body-hash-usuario': 'body-hash-cliente'
      }

      var request: TramiteRequest = {
        cuentaContrato: encriptCtaCto,
        parametros: parametros,
        co_resp_email: co_resp_email,
        id_contacto_sfdc: id_contacto_sfdc,
        opcion_sfdc: +opcion_sfdc
      }
      return this.client.post(
        `${this.configLoaderService.environment.endpoint}/api/Tramite/Tramite/otrosTramites?updateFor=${updateFor}`,
        request,
        {
          headers: new HttpHeaders(headerDictSupply),
          responseType: 'text'
        }
      );
    }
  }

  obtenerFechas(claimNumber: string): Observable<any> {
    let params = new HttpParams().set('claimNumber', claimNumber);
    return this.client.get<any>(`${this.configLoaderService.environment.endpoint}/api/utils/outage-claim`, { params: params});
  }

}
