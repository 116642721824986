import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  faPrint,
  faDownload,
  faPaperPlane,
  faPlus,
  faCreditCard,
  faDollarSign,
  faChevronDown,
  faTasks,
  faWallet,
  faMoneyCheck,
  faBraille,
  faCheckCircle,
  faTimes,
  faFileAlt,
  faInbox
} from '@fortawesome/free-solid-svg-icons';

import {AlertData} from 'src/app/shared/interfaces/alert-dialog/alert-data';
import { SharedService } from '../../service/shared/shared.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  faCheckCircle = faCheckCircle;
  faTimes = faTimes;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertData,
    private shared: SharedService
    ) { }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe({
      next: () => this.dismiss()
    });
  }

  dismiss() {
    this.shared.showOverlay.emit(false);
  }
}
