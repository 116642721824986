import  configuracion  from "../config.json";

export const environment = {
  production: configuracion.production,
  endpoint: configuracion.endpoint,
  endpointPagos: configuracion.endpointPagos,
  endpointFront: configuracion.endpointFront,
  endpointSdf: configuracion.endpointSfdc,
  datosPrueba: configuracion.datosPrueba,
  developmentMode: configuracion.developmentMode,
  keyHash: configuracion.keyHash,
  logRequestError: configuracion.logRequestError,
  logHeaderRequest: configuracion.logHeaderRequest,
  secretPuenteApp: configuracion.secretPuenteApp,
  usaCaptcha: configuracion.usaCaptcha,
  captchaKeyPublic: configuracion.captchaKeyPublic,
  rrss: configuracion.loginRS,
  dtmScript: configuracion.dtmScript,
  urlMapScheduledOutages: configuracion.urlMapScheduledOutages
};
