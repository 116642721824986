import { BehaviorSubject, Observable } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core'
import { UserInfo } from 'src/app/shared/models/userInfo'
import * as constantes from 'src/app/shared/helpers/constantes'
import { FormalitiesGesture } from 'src/app/shared/code/formalitiesGesture'
import { TramitesService } from 'src/app/shared/service/tramites/tramites.service'
import { SnackBarService } from 'src/app/shared/service/snackbar/snackbar.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Tokens } from 'src/app/modules/auth/interfaces/tokenInfo'
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router'
import { PersonalInformationService } from '../personal-information/personal-information.service'
import { ViewSuministroSelector } from '../../interfaces/view-suministro-selector'
import { rutasHabilitaSeleccionSuministro } from 'src/app/shared/helpers/constantes'
import { DeudaItem } from '../../models/general/DeudaItem'
import * as CryptoJS from 'crypto-js'
import { MatDialog } from '@angular/material/dialog'
import { SupplyRetrieveResponse } from '../../interfaces/SupplyRetrieveResponse'
import { ConfigLoaderService } from 'src/app/config/enviroment/config-loader.service'

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  datosUsuario: UserInfo = new UserInfo()
  constantesL = constantes
  GestorTramites: FormalitiesGesture
  cargando: boolean
  nroDeClientes: number;
  nroDeClienteEmitter: BehaviorSubject<number> = new BehaviorSubject<number> (null);
  clienteSeleccionado: ViewSuministroSelector
  listaDeudas: DeudaItem[] = []
  accesoFactura: string = ''
  userNoLogin: {
    email: string
    nroCtaContrato: string
    nroMedidor: string
    nombre: string
    direccion: string
  } = {
    email: '',
    nroCtaContrato: '',
    nroMedidor: '',
    nombre: '',
    direccion: ''
  }

  comprobanteConvenio: string = ''
  fechaHoy: Date
  fechaAnterior: Date
  listadoCuentas: SupplyRetrieveResponse = null
  listadoCuentasDetalle: ViewSuministroSelector[] = []

  @Output() suministroSeleccionado = new EventEmitter<ViewSuministroSelector>()
  @Output() suministroSeleccionadoFactura =
    new EventEmitter<ViewSuministroSelector>()
  @Output() mostrarSeleccionSuministro = new EventEmitter<boolean>()
  @Output() showOverlay = new EventEmitter<boolean>()
  @Output() showLoading = new EventEmitter<string>()
  @Output() respuestaPrisma = new EventEmitter<string>()
  @Output() updateSupplySelector = new EventEmitter<boolean>()
  @Output() updateUserInfo = new EventEmitter<boolean>()

  clientList?: number = null;
  clientListSubject: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
    private svcTramites: TramitesService,
    private snackBarService: SnackBarService,
    private svcPersonalInfo: PersonalInformationService,
    private router: Router,
    public dialog: MatDialog,
    private configLoaderService: ConfigLoaderService,
    //private analiticService: AnalyticsService
  ) {
    this.mockData();
    this.GestorTramites = new FormalitiesGesture(
      this,
      svcTramites,
      snackBarService,
      svcPersonalInfo,
      dialog,
      router,
      configLoaderService
    );
    this.initializeRouterReception();
    if (navigator.cookieEnabled) {
      let datosUsuario = localStorage.getItem('DATOSUSUARIO')
      if (datosUsuario) this.setearDatosLogin(JSON.parse(datosUsuario))

      let datosUsuarioNoLog = localStorage.getItem('DATOSUSUARIONOLOG')
      if (datosUsuarioNoLog)
        this.setearDatosUsuarioNoLogueado(JSON.parse(datosUsuarioNoLog))

      let datoSum = localStorage.getItem('DATOSSUMINISTRO')
      if (datoSum) this.setearDatosSuministro(JSON.parse(datoSum))
    }
    this.fechaHoy = new Date();
    this.fechaAnterior = new Date();
    this.fechaAnterior.setFullYear(this.fechaAnterior.getFullYear() - 1);
  }

  setNumeroDeClientes(nroDeClientes:number){
    this.nroDeClientes = nroDeClientes;
    this.nroDeClienteEmitter.next(this.nroDeClientes);
  }
  getNumeroDeClientes(){
    return this.nroDeClienteEmitter.asObservable();
  }

  getClientList(): Observable<number> {
    return this.clientListSubject.asObservable();
  }

  setClientList(count: number): void {
    this.clientList = count;
    this.clientListSubject.next(this.clientList);
  }

  initializeRouterReception() {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationEnd: {
          if (!this.cargando) {
            this.cargando = true
            setTimeout(() => {
              this.cargando = false
            }, 8000)
          }
          let url = ''
          if (event instanceof NavigationEnd) {
            url = event.url
            // TODO: revisar esto
            //this.analiticService.checkRoute(url.split('?')[0]);
            if (!url.includes('/login') && !navigator.cookieEnabled) {
              this.router.navigate(['/login'])
            }
          }
          if (
            rutasHabilitaSeleccionSuministro.includes(url) ||
            rutasHabilitaSeleccionSuministro.some((x) => url.includes(x))
          ) {
            this.mostrarSeleccionSuministro.emit(false)
          } else {
            this.mostrarSeleccionSuministro.emit(true)
          }

          break
        }
        default: {
          break
        }
      }
    })
  }

  mockData() {
    this.datosUsuario.origen = 'ONEHUB'
    this.datosUsuario.codigoEmpresa = '9'
    this.datosUsuario.tipoCliente = 'PERSONA'
  }

  setearDatosLogin(data: Tokens) {
    localStorage.setItem('DATOSUSUARIO', JSON.stringify(data))
    this.datosUsuario.nombre = data._sessionInfo.data.name
    this.datosUsuario.apellidoPaterno = data._sessionInfo.data.fatherLastname
    this.datosUsuario.tipodoc = data._sessionInfo.data.identityType
    this.datosUsuario.documento = data._sessionInfo.data.identityNumber
    this.datosUsuario.telefono = data._sessionInfo.data.mobilePhone
    this.datosUsuario.telefonoAlt = data._sessionInfo.data.homePhone
    this.datosUsuario.email = data._sessionInfo.data.email
    this.datosUsuario.username = data._sessionInfo.data.email
    this.datosUsuario.id_contacto_sfdc = data._sessionInfo.data.contactId
    this.datosUsuario.cambioTitularidad = data.cambioTitularidad
  }

  clearAfterLogout() {
    this.datosUsuario = new UserInfo();
    this.clienteSeleccionado = null;
    this.suministroSeleccionadoFactura.emit(null);
    this.suministroSeleccionado.emit(null)
  }

  setearDatosSuministro(
    suministro: ViewSuministroSelector,
    infoFactura: boolean = false
  ) {
    if (this.clienteSeleccionado != suministro) {
      localStorage.setItem('DATOSSUMINISTRO', JSON.stringify(suministro))
      this.clienteSeleccionado = suministro
      this.datosUsuario.nrocliente = suministro.detalle.CuentaContrato
      this.datosUsuario.nombrecliente = suministro.detalle.Nombre
      this.datosUsuario.direccion = `${suministro.detalle.DireccionCliente.Calle} ${suministro.detalle.DireccionCliente.Numero} B. ${suministro.detalle.DireccionCliente.Barrio} Loc. ${suministro.detalle.DireccionCliente.Localidad}`
      this.datosUsuario.suscritoEFactura = suministro.detalle.FacturaEmail
    }
    if (infoFactura) {
      this.suministroSeleccionadoFactura.emit(suministro)
    } else {
      this.suministroSeleccionado.emit(suministro)
    }
  }

  setearDatosUsuarioNoLogueado(data: {
    email: string
    nroCtaContrato: string
    nroMedidor: string
    nombre: string
    direccion: string
  }) {
    localStorage.setItem('DATOSUSUARIONOLOG', JSON.stringify(data))
    this.userNoLogin = data
  }

  obtenerDescripcionTramite(tramiteSeleccionado: number): string {
    for (const key in this.constantesL) {
      var tramiteObj = this.constantesL[key]
      if (tramiteObj.id == tramiteSeleccionado) {
        return tramiteObj.nombre
      }
    }
  }

  extensionArchivoValida(extension: string): boolean {
    var extension = this.constantesL.extensiones_archivos_validas.find(
      (x) => x == extension.toUpperCase()
    )
    return extension ? true : false
  }

  mostrarMensajeSnack(
    mensaje = 'Ejecución correcta',
    msgButton = 'OK',
    tiempo = 2000
  ) {
    this.snackBarService.addSnackBar(mensaje)
  }

  encriptarDatos(valor) { //Este metodo esta siendo utilizado en varios lugares habria que ver de usarlo adentro de las clases para evitar repetir codigo
    valor = valor ?? '';
    var key = CryptoJS.MD5(
      CryptoJS.enc.Utf8.parse(this.configLoaderService.environment.keyHash)
    )
    var iv = { ...key }
    iv.sigBytes = 8
    var mode = CryptoJS.mode.ECB
    var encrypted = CryptoJS.TripleDES.encrypt(valor.toString(), key, {
      iv,
      mode
    }).toString()

    return this.ConvertToSafeUrl(encrypted)
  }

  desencriptarDatos(valor: string) {
    var key = CryptoJS.MD5(
      CryptoJS.enc.Utf8.parse(this.configLoaderService.environment.keyHash)
    )
    var iv = { ...key }
    iv.sigBytes = 8
    var mode = CryptoJS.mode.ECB
    var decrypted = CryptoJS.TripleDES.decrypt(
      this.ConvertFromSafeUrl(valor),
      key,
      {
        iv,
        mode
      }
    ).toString()

    return decrypted
  }

  ConvertToSafeUrl(valor: string) {
    var resultado = ''
    resultado = valor.replace(/\//g, '_').replace(/\+/g, '-').replace(/=/g, '~')
    return resultado
  }
  ConvertFromSafeUrl(valor: string) {
    var resultado = ''
    resultado = valor.replace(/_/g, '/').replace(/-/g, '+').replace(/~/g, '=')
    return resultado
  }

  getBytesString(str: string) {
    var bytes = []
    for (var i = 0; i < str.length; ++i) {
      bytes.push(str.charCodeAt(i))
    }

    return bytes
  }

  //LISTA_CUENTAS
  setListadoCuentas(data: SupplyRetrieveResponse) {
    localStorage.setItem('LISTA_CUENTAS', JSON.stringify(data))
    localStorage.removeItem('LISTA_CUENTAS_DETALLE')
  }

  getListadoCuentas() {
    let result: SupplyRetrieveResponse = JSON.parse(
      localStorage.getItem('LISTA_CUENTAS')
    )
    return result
  }

  addDetailCuenta(dataDetail: ViewSuministroSelector) {
    let listado = JSON.parse(localStorage.getItem('LISTA_CUENTAS_DETALLE'))
    let listadoCast: ViewSuministroSelector[]
    if (listado) {
      listadoCast = JSON.parse(localStorage.getItem('LISTA_CUENTAS_DETALLE'))
    } else {
      listadoCast = []
    }
    if (!listadoCast.includes(dataDetail)) {
      listadoCast.push(dataDetail)
      localStorage.setItem('LISTA_CUENTAS_DETALLE', JSON.stringify(listadoCast))
    }
  }

  getListadoDetailsCuenta() {
    let result: ViewSuministroSelector[] = JSON.parse(
      localStorage.getItem('LISTA_CUENTAS_DETALLE')
    )
    return result
  }
}
