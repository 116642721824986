import { MessageStatus } from './../../../shared/models/message-status';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class DasboardCommunicationService {
  private statusMessage?: MessageStatus = null;
  private statusMessageSubject = new BehaviorSubject<MessageStatus>(null);
  // Observable string sources
  private componentMethodCallSource = new Subject<any>();

  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  // Service message commands
  changePageTileMethod(data) {
    this.componentMethodCallSource.next(data);
  }

  setStatusMessage(message: MessageStatus): void {
    this.statusMessage = message;
    this.statusMessageSubject.next(this.statusMessage);
  }

  getStatusMessage(): Observable<MessageStatus> {
    return this.statusMessageSubject.asObservable();
  }

  clearStatusMessage(): void {
    this.statusMessage = null;
    this.statusMessageSubject.next(null);
  }

}
