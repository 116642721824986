import { UpdateService } from './update.service';
import { TagManagerService } from 'src/app/shared/analitycs/domain/tag-manager.service';
import { Component, AfterContentInit, OnInit } from '@angular/core'
import { Router, NavigationEnd, Route } from '@angular/router'
import { SharedService } from './shared/service/shared/shared.service'
import { MatDialog } from '@angular/material/dialog'
import packageconf from '../../package.json';

@Component({
  selector: 'app-root',
  template: `
  <div *ngIf="updatable" class="d-flex align-items-center custom-border-left-warning" style="border-radius: 0;">
    <p class="font-size-16px-to-rem m-0 line-height-18px-to-rem td-red p-2">
      Actualizacion pendiente!.
      <button (click)="updateVersion()" class="btn btn-custom-orange font-size-10px-to-rem">
        ¡Actualizar a la nueva versión!
      </button>
    </p>
  </div>
  <div class="fadeMe" *ngIf="overlay">
    <div class="d-flex align-items-center justify-content-center h-100 w-100">
      <app-spinner></app-spinner>
    </div>
  </div>
  <router-outlet></router-outlet>
`
})
export class AppComponent implements AfterContentInit {
  public version: string = packageconf['version'];
  title = 'Edesur'
  timer = 0
  updatable = false;

  objectArrayAccountSummary = [
    {
      name: 'Hernan Soto',
      address: 'Pitágoras 2058',
      clientNumber: '4656387-5 / 2345678654',
      measurerNumber: '',
      status: 'active',
      price: '100.143,010'
    }
  ]

  objectArrayPlaumentPlans = [
    {
      fechaInicio: '24/12/20',
      deudaInicial: '$100.143,010',
      anticipo: '$536,046',
      interesMensual: '2,4%',
      cuotas: '6',
      monto: '$357,354',
      estado: 'Solicitado'
    }
  ]

  objectArrayClaim = [
    {
      tipo: 'Artefactos dañados',
      fecha: '24/12/20',
      adjuntos: 'Si',
      estadoReclamo: 'En proceso',
      pdf: ''
    },
    {
      tipo: 'Objeción lectura medidor',
      fecha: '30/12/20',
      adjuntos: 'Si',
      estadoReclamo: 'Cerrado',
      pdf: 'Resolución 123.pdf'
    }
  ]

  user: any = {
    user: '',
    password: '',

    token: '',
    sesionId: ''
  }

  view: boolean = false

  overlay: boolean = false

  constructor(
    private route: Router,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private updateService: UpdateService,
    private tagManagerService: TagManagerService
  ) {
    //this.updateService.observForUpdates();
    this.updateService.isUpdatable().subscribe({
      next: (updatable) => this.updatable = updatable,
    });
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tagManagerService.emitPageEvent(event.urlAfterRedirects);
      }
    });

    if (navigator.cookieEnabled) {
      localStorage.setItem(
        'tablaResumenDeCuenta',
        JSON.stringify(this.objectArrayAccountSummary)
      )
      localStorage.setItem(
        'tablaPlanesDePago',
        JSON.stringify(this.objectArrayPlaumentPlans)
      )
      localStorage.setItem(
        'tablaReclamos',
        JSON.stringify(this.objectArrayClaim)
      )
      localStorage.setItem('usuario', JSON.stringify(this.user))
    }
  }

  updateVersion() {
    this.updateService.updateVersion();
  }

  printpath(parent: String, config: Route[]) {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];
      if (route.children) {
        const currentPath = route.path ? parent + '/' + route.path : parent;
        this.printpath(currentPath, route.children);
      }
    }
  }

  ngAfterContentInit(): void {
    console.info('Edesur version: ', this.version);
    this.sharedService.showOverlay.subscribe((showOverlay: boolean) => {

      setTimeout(() => this.overlay = showOverlay, 0);

      if (this.timer != 0) {
        clearTimeout(this.timer);
      }
      if (showOverlay) {
        this.timer = setTimeout(() => {
          this.overlay = false;
          this.route.navigateByUrl('mi-cuenta');
        }, 60000);
      }
    })
  }

  ngOnInit(): void {}
}
