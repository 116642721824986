import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, tap } from 'rxjs/operators'
import { AppConfig } from './model/config.model'
import  configuracion  from 'src/config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {
  public environment: AppConfig

  constructor(private httpClient: HttpClient) {}

  initialize() {
    this.environment = configuracion;


  }
}
