import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetProfile, UpdateProfile } from 'src/app/shared/models/personal-information/personal-information-request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetProfileUsserResponse,
  GetUpdateProfileUsserResponse,
} from '../../models/personal-information/personal-information-response';
import { ConfigLoaderService } from 'src/app/config/enviroment/config-loader.service';
import { ChangeEmailRequest } from 'src/app/shared/interfaces/change-email-request';
import { ChangeEmailResponse } from '../../interfaces/change-email-response';



@Injectable({
  providedIn: 'root',
})
export class PersonalInformationService {
  directionChange: string = '/api/Usuario/ChangeEmail';
  constructor(private client: HttpClient, private configLoaderService: ConfigLoaderService) {}

  obtenerPerfilUsuario(
    parametros: GetProfile,
    useCahe: boolean = false,
  ): Observable<GetProfileUsserResponse> {
    const headerDict = {
      'RequestKey': 'username',
      'TipoValidacion': 'body-hash-usuario',
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.client.post<GetProfileUsserResponse>(
      `${this.configLoaderService.environment.endpoint}/api/Usuario/GetProfile` + '?useCache=' + useCahe,
      parametros,requestOptions
    );
  }

  actualizarPerfilUsuario(
    parametros: UpdateProfile
  ): Observable<GetUpdateProfileUsserResponse> {
    const headerDict = {
      'RequestKey': 'username',
      'TipoValidacion': 'body-hash-usuario',
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.client.post<GetUpdateProfileUsserResponse>(
      `${this.configLoaderService.environment.endpoint}/api/Usuario/SetProfile`,
      parametros,requestOptions
    );
  }

  actualizarEmailUsuario(parameters: ChangeEmailRequest): Observable<ChangeEmailResponse>{
    let url = this.configLoaderService.environment.endpoint + this.directionChange;
    return this.client.post<ChangeEmailResponse>(url, parameters);
  }
    
}
