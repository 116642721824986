import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  SimpleSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { AlertData } from '../../interfaces/alert-dialog/alert-data';

export class SnackBarMessage {
  message: string;
  action: string = null;
  config: MatSnackBarConfig = null;
}
@Injectable({
  providedIn: 'root',
})
export class SnackBarService implements OnDestroy {
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private msgQueueSnack = [];
  private isInstanceVisibleSnack = false;

  private msgQueue: AlertData[] = [];
  private isInstanceVisible = false;

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog) {}

  showNextSnack() {
    if (this.msgQueueSnack.length <= 0) {
      return;
    }

    let message = this.msgQueueSnack.shift();
    this.isInstanceVisibleSnack = true;
    this.snackBarRef = this.snackBar.open(message.message, message.action, {
      duration: 10000,
    });
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.isInstanceVisibleSnack = false;
      this.showNextSnack();
    });
  }

  showNext() {
    if (this.msgQueue.length <= 0) {
      return;
    }

    let message = this.msgQueue.shift();
    this.isInstanceVisible = true;

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      data: message,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //redirect mi-cuenta
      this.isInstanceVisible = false;
      this.showNext();
    });
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  /**
   * Add a message
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  addSnackBar(
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  ): void {
    let sbMessage = new SnackBarMessage();
    sbMessage.message = message;
    sbMessage.action = action;
    if (config) sbMessage.config = config;

    this.msgQueueSnack.push(sbMessage);
    if (!this.isInstanceVisibleSnack) {
      this.showNextSnack();
    }
  }

  /**
   * Add a message
   * @param exito (boolean) indica si la alerta es de un mensaje exitoso o no
   * @param titulo (string) titulo que tendra la alerta <h4 class="text-center mb-3"></h4>
   * @param cuerpo (string) cuerpo que tendra de la alerta <p class="mb-0"><p>
   * @param usaHTML (boolean) indica si se va utilizar un custom html
   * @param innerHTML (string) custom html que tendra la alert, solo body
   */
  add(
    exito: boolean = true,
    titulo: string = '',
    cuerpo: string = '',
    usaHTML: boolean = false,
    innerHTML: string = ''
  ): void {
    let bodyAlert: AlertData;

    if (usaHTML) {
      bodyAlert = {
        htmlContent: innerHTML,
        state: exito,
        showConfirmButtons: 2
      };
    } else {
      bodyAlert = {
        htmlContent: `<h4 class="text-center mb-3">${titulo}</h4><p class="mb-0">${cuerpo}</p>`,
        state: exito,
        showConfirmButtons: 2
      };
    }
    this.msgQueue.push(bodyAlert);
    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }
}
