import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../shared/service/snackbar/snackbar.service';
import { ConfigLoaderService } from '../../config/enviroment/config-loader.service';
import { channel_web } from '../../shared/helpers/constantes';
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public authService: AuthService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private configLoaderService: ConfigLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = {
      'x-origin-channel': channel_web,
    };
    const uniqueId = Date.now().toString();
    const logRequest = `REQUEST: ID-${uniqueId} | Origen-${
      this.activatedRoute.snapshot['_routerState'].url
    } | Endpoint-${request.url} | Hora-${new Date().toLocaleString()}`;

    if (this.authService.getJwtToken()) {
      const authHeaders = this.addToken(request, this.authService.getJwtToken());
      headers = {...headers, ...authHeaders};
    }

    if(this.configLoaderService.environment.logHeaderRequest){

    }
    // Add custom Application headers
    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      tap({
        next: () => {},
        error: (err: any) => {
          if (this.configLoaderService.environment.logRequestError) {
            console.error(`ERROR REQUEST: ID-${uniqueId}`);
            this.registerError(this.getObjRequest(uniqueId,request));
          }

          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.authService.doLogoutUser();
              this.router.navigate(['login']).then((navigated: boolean) => {
                if (navigated) {
                  this.snackBarService.addSnackBar(
                    'Tu sesión ha caducado, volvé a loguearte nuevamente.'
                  );
                }
              });
            }
          }
        }
      }));
  }

  private addToken(request: HttpRequest<any>, token: string): any {
    var headers = {};
    headers['Authorization'] = `Bearer ${token}`;
    headers['tipologueo'] = this.authService.loginExterno
      ? 'externo'
      : 'interno';
    request.headers.keys().forEach((element) => {
      headers[element] = request.headers.get(element);
    });
    return headers;
  }

  getObjRequest(idRequest: string,request: HttpRequest<any>) {
    var listHeaders = request.headers.keys().map((x) => {
      var obj = {};
      obj[x] = request.headers.get(x);
      return obj;
    });

    return {
      'id': idRequest,
      'headers': listHeaders,
      'fechaIncidencia': new Date().toLocaleString(),
      'endpoint':  request.url
    }

  }

  registerError(obj){
    const key = 'REQUEST-ERRORS';
    let errors = JSON.parse(localStorage.getItem(key));
    let errorObj = {
      "errors":[],
      "updated": new Date().toDateString()
    };
    if(errors){
      errorObj.errors = errors.errors;
    }
    errorObj.errors.push(obj);
    localStorage.setItem(key, JSON.stringify(errorObj));
  }
}
