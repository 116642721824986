import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { of, Observable } from 'rxjs'
import { catchError, mapTo, tap } from 'rxjs/operators'
import { Tokens } from './interfaces/tokenInfo'
import { LoginSocialNetworkForm } from './interfaces/loginSocialNetworkForm'
import { SnackBarService } from 'src/app/shared/service/snackbar/snackbar.service'
import { SharedService } from 'src/app/shared/service/shared/shared.service'
import { ConfigLoaderService } from 'src/app/config/enviroment/config-loader.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'token'
  private readonly SESSION_ID = 'sesionId'
  private readonly USER_ID = 'userId'
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN'
  loggedUser: string = ''
  loginExterno: boolean = false

  constructor(
    private http: HttpClient,
    private sharedServ: SharedService,
    private configLoaderService: ConfigLoaderService
  ) {
    let tipoLogin = localStorage.getItem('LOGINEXTERNO')
    this.loginExterno = tipoLogin == 'true' ? true : false
  }

  login(user: { email: string; password: string }): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.configLoaderService.environment.endpoint}/api/Usuario/Login`,
        user
      )
      .pipe(
        tap((tokens) => this.doLoginUser(user.email, tokens)),
        mapTo(true),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            // filtrar los casos de error con mensaje de salida
            if (typeof err.error === 'string') {
              throw { message:  err.error};
            } else {
              throw { message: (err.error as any)?.title ?? 'Error desconocido'};
            }
          }
          return of(true);
        })
      )
  }

  LoginSocNet(
    socialMediaId: string,
    socialMediaEmail: string
  ): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.configLoaderService.environment.endpoint}/api/Usuario/LoginSocNet?socialMediaId=${socialMediaId}&socialMediaEmail=${socialMediaEmail}`,
        {}
      )
      .pipe(
        tap((tokens) => this.doLoginUser('', tokens)),
        mapTo(true),
        catchError((error) => {
          return of(false)
        })
      )
  }

  GetSocialNetworkLoginForm(request: {
    socialNetwork: string
    urlRedirect: string
  }) {
    return this.http.post<LoginSocialNetworkForm>(
      `${this.configLoaderService.environment.endpoint}/api/Usuario/GetLoginFormSocNet`,
      request
    )
  }

  loginClienteMedidor(request: {
    cuentaContrato: string
    nroMedidor: string
  }): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.configLoaderService.environment.endpoint}/api/Cliente/Login`,
        request
      )
      .pipe(
        tap((tokens) => this.doLoginUser('clientemedidor', tokens, true)),
        mapTo(true),
        catchError((error) => {
          console.log('ERROR', error)
          if (error.error && typeof(error.error) === 'string') {
            throw { message:  error.error};
          } else if (error.error && typeof(error.error) === 'object') {
            throw { message:  error.error.message};
          }
          return of(false)
        })
      )
  }
  loginPuenteApp(
    request: {
      cuentaContrato: string
      nroMedidor: string
    },
    secret: string
  ): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.configLoaderService.environment.endpoint}/api/Cliente/LoginPuenteApp?temporalToken=${secret}`,
        request
      )
      .pipe(
        tap((tokens) => this.doLoginUser('clientemedidor', tokens, true)),
        mapTo(true),
        catchError((error) => {
          return of(false)
        })
      )
  }

  logout(request: {
    Token: string
    SesionId: string
  }): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.configLoaderService.environment.endpoint}/api/Usuario/Logout`,
        request
      )
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError((error) => {
          return of(false)
        })
      )
  }

  isLoggedIn() {
    return !!this.getJwtToken()
  }

  doLoginUser(username: string, tokens: Tokens, externo: boolean = false) {
    this.loggedUser =
      username !== '' ? username : tokens._sessionInfo.data.email
    this.loginExterno = externo
    localStorage.setItem('LOGINEXTERNO', externo ? 'true' : 'false')
    if (externo) {
      tokens._sessionInfo.data = {
        userId: '',
        sessionId: username,
        notifications: true,
        name: '',
        motherLastname: '',
        mobilePhone: '',
        identityType: '',
        identityNumber: '',
        homePhone: '',
        fatherLastname: '',
        email: '',
        countServiceProduct: '',
        contactId: ''
      }
    }
    this.sharedServ.setearDatosLogin(tokens)
    this.storeTokens(tokens)
  }

  doLogoutUser() {
    this.sharedServ.showOverlay.emit(false)
    this.loggedUser = null
    this.removeTokens()
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.token)
    localStorage.setItem(this.SESSION_ID, tokens._sessionInfo.data.sessionId)
    localStorage.setItem(this.USER_ID, tokens._sessionInfo.data.userId)
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN)
    localStorage.removeItem(this.SESSION_ID)
    localStorage.removeItem(this.USER_ID)
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN)
    //let token = this.getCookie('Authorization')
    //return token.replace('Bearer ', '');
  }

  getSessionId() {
    return localStorage.getItem(this.SESSION_ID)
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN)
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt)
  }

  refreshToken() {
    //example refresh token
    return this.http
      .post<any>(`${this.configLoaderService.environment.endpoint}/refresh`, {
        refreshToken: this.getRefreshToken()
      })
      .pipe(
        tap((tokens: Tokens) => {
          this.storeJwtToken(tokens.token)
        })
      )
  }

  private storeTokensClienteMedidor(token: string) {
    localStorage.setItem(this.JWT_TOKEN, token)
  }

  InicioCambioTitularidad(request: {
    cuentaContrato: string
    nroMedidor: string
  }): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.configLoaderService.environment.endpoint}/api/Cliente/InicioCambioTitularidad`,
        request
      )
      .pipe(
        tap((tokens) => this.doLoginUser('clientemedidor', tokens, true)),
        mapTo(true),
        catchError((error) => {
          if (error.error && typeof(error.error) === 'string') {
            throw { message:  error.error};
          }
          return of(false)
        })
      )
  }

}
