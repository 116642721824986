import { DasboardCommunicationService } from './modules/dashboard/services/dasboard-communication.service';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/*Modulos*/
import { WelcomeModule } from './modules/welcome/welcome.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AccountSummaryModule } from './modules/account-summary/account-summary.module';
import { PaymentsAndInvoicesModule } from './modules/payments-and-invoices/payments-and-invoices.module';
import { FormalitiesModule } from './modules/formalities/formalities.module';
import { AuthInterceptor } from './modules/auth/auth-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { PersonalInformationModule } from './modules/personal-information/personal-information.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from 'src/app/config/paginatorConfig';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';;
import { TagManagerService } from 'src/app/shared/analitycs/domain/tag-manager.service';
import { AdobeDtmService } from 'src/app/shared/analitycs/infrastructure/adobe-dtm.service';

import { PreloadFactory } from 'src/app/config/enviroment/preload-service.factory';
import { ConfigLoaderService } from 'src/app/config/enviroment/config-loader.service';

/* Validacion de formularios */

/* Filters */
import { CurrencyPipe } from '@angular/common';
import { ReplacePipe } from './shared/pipelines/replace/replace.pipe';
import {
  FacebookLoginProvider,
  GoogleInitOptions,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule } from '@abacritt/angularx-social-login';
import { ServiceWorkerModule } from '@angular/service-worker';

declare const window: any;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    /* Validator */
    ReactiveFormsModule,
    FormsModule,
    /* Filter */
    RecaptchaModule,
    /* for providers */
    MatSnackBarModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    ConfigLoaderService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigLoaderService],
      multi: true,
      useFactory: PreloadFactory
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.rrss.google.google_client_id, {
                oneTapEnabled: true,
                use_fedcm_for_prompt: true
              } as GoogleInitOptions)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.rrss.facebook.Facebook_AppId),
          }
        ],
        onError: (err) =>{
          if(err.details === 'Cookies are not enabled in current environment.') {
            window._THIRDPARTYCOOKIES = '0';
          } else {
            window._THIRDPARTYCOOKIES = '1';
          }
        },
      } as SocialAuthServiceConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    {
      provide: TagManagerService,
      useClass: AdobeDtmService,
    },
    CurrencyPipe,
    ReplacePipe,
    MatSnackBarModule,
    MatDialogModule,
    DasboardCommunicationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
