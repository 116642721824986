<div id="popup-container">
  <mat-dialog-content class="mat-typography">
    <div>
      <div id="pagoPrismaRealizado" tabindex="-1" role="dialog" aria-labelledby="pagoPrismaRealizado">
        <div role="document">
          <div>
            <fa-icon id="icon-popup" [className]="data.state ? 'text-success' : 'text-danger'"
              [icon]="data.state? faCheckCircle: faTimes"></fa-icon>
            <div class="text-dark" [innerHTML]="data.htmlContent"></div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
    <div class="py-3" mat-dialog-actions>
      <button *ngIf="data.showConfirmButtons == 0" class="m-auto button-popup" mat-button [mat-dialog-close]="{respuesta: true}" cdkFocusInitial  role="button" aria-label="¡De acuerdo!">¡De acuerdo!</button>
      <button *ngIf="data.showConfirmButtons == 2" class="m-auto button-popup" mat-button [mat-dialog-close]="data" cdkFocusInitial [routerLink]="'/mi-cuenta'"  role="button" aria-label="¡De acuerdo!">¡De acuerdo!</button>
      <div *ngIf="data.showConfirmButtons == 1" class="d-flex justify-content-space-between align-items-center w-100">
        <button class="m-auto button-popup" mat-button [mat-dialog-close]="{respuesta: false}" cdkFocusInitial  role="button" aria-label="No">No</button>
        <button class="m-auto button-popup" mat-button [mat-dialog-close]="{respuesta: true}" cdkFocusInitial  role="button" aria-label="Si">Si</button>
      </div>
      <div *ngIf="data.showConfirmButtons == 3" class="btn-container d-flex m-auto justify-content-between w-80 flex-wrap">
          <button type="button" class="btn btn-custom-orange px-3"
            [mat-dialog-close]="{respuesta: true}" cdkFocusInitial [routerLink]="'/tramites'"  role="button" aria-label="Cancelar">Si, cancelar</button>
          <button type="button" class="btn btn-custom-cancel px-3"
            [mat-dialog-close]="{respuesta: false}" cdkFocusInitial  role="button" aria-label="Continuar">No, continuar</button>
      </div>
      <div *ngIf="data.showConfirmButtons == 4" class="d-flex m-auto justify-content-around">
        <button class="btn btn-secondary" mat-button [mat-dialog-close]="{respuesta: false}"   role="button" aria-label="Cancelar">Cancelar</button>
        <button class="btn button-popup" mat-button [mat-dialog-close]="{respuesta: true}" cdkFocusInitial  role="button" aria-label="Aceptar">Aceptar</button>
      </div>
    </div>
  </div>
